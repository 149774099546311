<template>
<div>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12" class="pb-2 px-1">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <div>
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              title="Nuevo Feriado"
              small
              fab
              @click="nuevoFeriado()"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
          </div>
          <v-expansion-panel-header class="py-0 px-4 text-h5">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row class="pt-2">
                <v-col cols="12" sm="6" md="2" class="pt-1">
                  Año
                  <v-text-field
                    v-model="filtro.anio"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="pt-1">
                  Fecha del Feriado
                  <FechaPickerVue v-model="filtro.fecha" clearable hide-details/>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="pt-1">
                  Tipo Feriado
                  <v-autocomplete
                    v-model="filtro.tipo_id"
                    item-text="tipo_nombre"
                    item-value="tipo_id"
                    tabindex="1"
                    :items="tipos"
                    hide-details
                    outlined
                    dense
                    clearable 
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="pt-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal_codigo"
                    item-text="sucursal_nombre"
                    item-value="sucursal_codigo"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    outlined
                    dense
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="pt-1">
                  Nombre Feriado
                  <v-text-field
                    v-model="filtro.feriado_nombre"
                    type="text"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="2" class="d-flex justify-end">
                  <BtnFiltroVue
                    :loading="load"
                    @action="buscar()"
                    clase="mt-0"
                    @clear="limpiarFiltros()"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <!-- DETALLES -->
    <v-col cols="12" class="elevation-2 px-1">
      <v-data-table
        class="cebra mt-2 elevation-1"
        :headers="header"
        :items="feriados"
        :loading="load"
        :search="search"
        sort-by="fecha"
        :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
        dense
      >
        <!-- Busqueda -->
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-3" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTableVue
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
        <template v-slot:[`item.acciones`]="{item}">
          <v-tooltip top>
            <template  v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="orange"
                small
                @click="editar(item)"
              >
                fas fa-edit
              </v-icon>
            </template>
            <span> Editar </span>
          </v-tooltip>
          <v-tooltip bottom >
            <template v-slot:activator="{ on }">
              <v-icon
                class="px-2"
                @click="eliminar(item)"
                v-on="on"
                color="error"
                small
              >
                fas fa-times
              </v-icon>
            </template>
            <span> Eliminar </span>
          </v-tooltip>
        </template>
        <!-- Fecha -->
        <template v-slot:[`item.fecha`]="{item}">
          {{ moment(item.fecha).format('DD/MM/YY') }}
        </template>
         <!-- Formato moneda -->
        <template
          v-for="header in header.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:[`item.liq_tar_proyectada`]="{item}">
          <v-checkbox
            v-model="item.liq_tar_proyectada"
            readonly
            class="ma-0 pb-1"
            dense
            hide-details
          ></v-checkbox>
        </template>
        <template v-slot:[`item.ventas`]="{item}">
          <v-checkbox
            v-model="item.ventas"
            readonly
            dense
            class="ma-0 pb-1"
            hide-details
          ></v-checkbox>
        </template>
      </v-data-table> 
    </v-col>
  </v-row>
  <ModalNvoEditarFeriadosVue 
  v-model="objModal.activo"
  :datos="objModal"
  @guardarCambios="guardarCambios"
  />
</div>
</template>

<script>
import moment from 'moment'
import moment2 from 'moment-timezone';
import BtnFiltroVue from '../../components/util/BtnFiltro.vue'
import SearchDataTableVue from '../../components/util/SearchDataTable.vue'
import { order_list_by } from '../../util/utils'
import ModalNvoEditarFeriadosVue from '../../components/generales/ModalNvoEditarFeriados.vue'
import FechaPickerVue from '../../components/util/FechaPicker.vue';

export default {
  name: 'Feriados',
  data(){
    return{
      moment: moment,
      panel: 0,
      load: false,
      search: '',
      filtro:{
        anio: (new Date).getFullYear(),
        tipo_id: null,
        tipo_nombre: null,
        surcursal_codigo: null,
        sucursal_nombre: null,
        fecha: null,
        feriado_nombre: null, 
      },
      header:[
        { text: 'Año' , value: 'anio' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Tipo feriado' , value: 'nombre_tipo_feriado' },
        { text: 'Feriado' , value: 'nombre_feriado' },
        { text: 'Sucursal' , value: 'sucursal_nombre' },
        { text: 'Ventas', value: 'ventas' },
        { text: 'Proyectada', value:'liq_tar_proyectada'},
        { text: 'Acciones', value: 'acciones', filtreable: false, sorteable: false, align: 'center' }
      ],
      feriados:[],
      tipos: [],
      sucursales: [],
      objModal: {
        activo: false,
        nuevo: false,
        feriado: {
          id: 0,
          anio: null,
          fecha: null,
          id_tipo: null,
          nombre_feriado: null,
          nombre_tipo_feriado: null,
          sucursal_codigo: null,
          sucursal_nombre: null,
          ventas: 1,
          liq_tar_proyectada: 1,
        },
        tipos: [],
        sucursales: [],
      }
    }
  },
  created(){
    this.init();
  },
  methods:{
    async init(){
      this.$store.state.loading = true;
      const res = await this.$store.dispatch('feriados/iniciarFormulario')
      this.$store.state.loading = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
      }
      this.tipos = res.tipos; order_list_by(this.tipos, 'tipo_nombre')
      this.sucursales = res.sucursales;
    },
    async buscar(){
      if(!this.filtro.anio){
        return this.$store.dispatch('show_snackbar', { text: 'Es necesario ingresar el Año', color: 'warning' })
      }

      this.load = true;
      this.feriados = [];
      this.search = '';
      this.$store.state.loading = true;
      
      const res = await this.$store.dispatch('feriados/buscarFeriados', this.filtro)
      this.$store.state.loading = false;
      this.load = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
      }
      this.feriados = res.feriados;
    },
    limpiarFiltros(){
      this.feriados = [];
      this.search = '';
      this.filtro = {
        anio: (new Date).getFullYear(),
        tipo_id: null,
        tipo_nombre: null,
        surcursal_codigo: null,
        sucursal_nombre: null,
        fecha: null,
        feriado_nombre: null, 
      }
    },
    nuevoFeriado(){
      let feriado = {
        id: 0,
        anio: null,
        fecha: null,
        id_tipo: null,
        nombre_feriado: null,
        nombre_tipo_feriado: null,
        sucursal_codigo: null,
        sucursal_nombre: null,
        ventas: 1,
        liq_tar_proyectada: 1,
      };
      this.objModal.tipos = this.tipos;
      this.objModal.sucursales = this.sucursales;
      this.objModal.feriado = feriado;
      this.objModal.nuevo = true;
      this.objModal.activo = true;
    },
    async guardarCambios(item, nuevo){
      //return
      this.$store.state.loading = true;
      const res = await this.$store.dispatch(nuevo? 'feriados/nuevoFeriado' : 'feriados/actualizarFeriado', item)
      this.$store.state.loading = false;

      if(res.resultado == 0){ return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', }); }
      
      this.$swal.fire({
        icon: 'success',
        title: nuevo ? 'Feriado creado' : 'Feriado actualizado',
        text: nuevo ? 'Se creo el feriado con éxito' : 'Se actualizó correctamente el feriado.',
        timer: 3000
      });
      let fechaAux = item.fecha.split("/").reverse().join("/");
      fechaAux = fechaAux.replaceAll('/', '-')
      item.fecha = moment2.tz(fechaAux+' 12:00:00', 'YYYY-MM-DD HH:mm:ss', 'UTC').format()//moment(item.fecha, 'DD/MM/YYYY').toDate();
      if(nuevo){
        this.feriado = [];
        item.id = res.id;
        this.feriados.push(item)
      }else{
        let pos = this.feriados.map(e => e.id).indexOf(item.id);
        Object.assign(this.feriados[pos], item);
      }
      this.objModal.activo = false;
    },
    async eliminar(item){
      const resp = await this.$swal.fire({
        icon: 'warning',
        title: `Está seguro de eliminar este feriado. ¿Confirma esta acción?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      });
      if(resp.isConfirmed){
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('feriados/eliminarFeriado', { id: item.id })
        this.$store.state.loading = false;

        if(res.resultado == 0){ return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', }); }
        
        this.$swal.fire({
          icon: 'success',
          title: 'Feriado eliminado.',
          text: 'Se eliminó el feriado con éxito.',
          timer: 3000
        });

        let pos = this.feriados.indexOf(item);
        this.feriados.splice(pos,1);
      }
    },
    editar(item){
      this.objModal.tipos = this.tipos;
      this.objModal.sucursales = this.sucursales;
      this.objModal.feriado = item;
      this.objModal.nuevo = false;
      this.objModal.activo = true;
    }
  },
  computed:{
  },
  components:{
    BtnFiltroVue, SearchDataTableVue, ModalNvoEditarFeriadosVue, FechaPickerVue
  },
  watch:{

  }
}
</script>

<style>

</style>